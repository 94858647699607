<template>
  <v-container
    id="create-formation"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <v-form
          method="post"
          ref="formation"
          @submit.prevent="validate"
        >
          <base-material-card
            color="primary"
          >
            <template v-slot:heading>
              <div
                v-if="!$route.params.id"
                class="display-2 font-weight-light"
              >
                {{ $t('create-session') }}
              </div>
              <div
                v-if="$route.params.id"
                class="display-2 font-weight-light"
              >
                Mettre à jour la session
              </div>
            </template>

            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    outlined
                    :items="courses"
                    item-text="name"
                    item-value="id"
                    label="Cours *"
                    v-model="formation.cours"
                    clearable
                    return-object
                    required
                    :rules="[v => !!v || 'Indiquez le cours']"
                    @input="updateIntituleRef()"
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="Intitulé *"
                    v-model="formation.intitule"
                    hint="Exemple : Formation HTML 5 et CSS 3"
                    :rules="[v => !!v || 'Indiquez l\'intitulé']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    outlined
                    label="Référence *"
                    v-model="formation.reference"
                    hint="Ex. : HTC001"
                    :rules="[v => !!v || 'Indiquez la réf.']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  v-if="!$route.params.id"
                  cols="12"
                  md="4"
                >
                  <v-select
                    outlined
                    :items="types"
                    v-model="formation.type"
                    item-text="intitule"
                    item-value="type"
                    label="Type *"
                    :rules="[v => !!v || 'Indiquez le type de session']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    outlined
                    :items="speakers"
                    item-text="prenom"
                    item-value="id"
                    label="Formateur principal"
                    v-model="formateur"
                    return-object
                    @input="updateFormateurCreneaux()"
                    @change="formEdited = true"
                  >
                    <template
                      slot="selection"
                      slot-scope="data"
                    >
                      {{ data.item.prenom }} {{ data.item.nom }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      {{ data.item.prenom }} {{ data.item.nom }}
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col
                  cols="6"
                >
                  <v-menu
                    ref="menuDateDebut"
                    v-model="menuDateDebut"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="dateDebutFormatted"
                        label="Date de début *"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :rules="[v => !!v || 'Indiquez la date de début']"
                        @blur="formation.dateDebut = parseDate(dateDebutFormatted)"
                        required
                        @change="formEdited = true"
                      />
                    </template>
                    <v-date-picker
                      class="mt-0 create-formation-date-picker"
                      v-model="formation.dateDebut"
                      :first-day-of-week="1"
                      locale="fr"
                      @input="menuDateDebut = false; openDateFin();"
                      @change="saveDateDebut"
                      :pickerDate.sync="pickerDate"
                    />
                  </v-menu>
                </v-col>

                <v-col
                  cols="6"
                >
                  <v-menu
                    ref="menuDateFin"
                    v-model="menuDateFin"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    @input="createCreneaux();"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="dateFinFormatted"
                        label="Date de fin *"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :rules="[v => !!v || 'Indiquez la date de fin']"
                        required
                        :disabled="!dateDebutFormatted"
                        @blur="formation.dateFin = parseDate(dateFinFormatted)"
                        @change="formEdited = true"
                      />
                    </template>
                    <v-date-picker
                      class="mt-0 create-formation-date-picker"
                      v-model="formation.dateFin"
                      :first-day-of-week="1"
                      locale="fr"
                      @input="menuDateFin = false; createCreneaux();"
                      @change="saveDateFin"
                      :pickerDate.sync="pickerDate"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="formation.type === 'INTRA'"
                  cols="12"
                  md="6"
                >
                  <v-tooltip
                    right
                    color="warning"
                    :disabled="!Number($route.params.id)"
                    close-delay="1500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-autocomplete
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        :items="clientsDirect"
                        item-text="nomCommercial"
                        item-value="id"
                        label="Client"
                        v-model="formation.client"
                        return-object
                        clearable
                        @change="formEdited = true"
                      />
                    </template>
                    <span>Attention : modifier le client supprimera tous les stagiaires inscrits</span>
                  </v-tooltip>
                </v-col>

                <v-col
                  v-if="formation.type === 'SSTRT'"
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    outlined
                    :items="clientsOF"
                    item-text="nomCommercial"
                    item-value="id"
                    label="Organisme"
                    v-model="formation.client"
                    return-object
                    clearable
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  v-if="formation.type === 'SSTRT'"
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="Nom du client final"
                    v-model="formation.nomClientFinal"
                    hint="Pour les formations sous-traitées en intra-entreprise uniquement, sinon laisser vide"
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="6"
                  md="3"
                >
                  <v-switch
                    v-model="formation.distanciel"
                    label="La formation a lieu en classe virtuelle"
                    @change="isChezClient = false; formation.adresse = {}"
                  />
                </v-col>
                <v-col
                  v-if="(!formation.distanciel) && (formation.type === 'INTRA' || formation.type === 'SSTRT') && formation.client"
                  cols="6"
                  md="3"
                >
                  <v-switch
                    v-model="isChezClient"
                    label="La formation a lieu chez le client"
                    @change="fillAdresse"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="!formation.distanciel"
              >
                <v-col
                  cols="12"
                >
                  <v-textarea
                    outlined
                    label="Adresse"
                    v-model="formation.adresse.adressePostale"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Code postal"
                    v-model="formation.adresse.codePostal"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Ville"
                    v-model="formation.adresse.ville"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Pays"
                    v-model="formation.adresse.pays"
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    outlined
                    v-model="formation.commentaires"
                    label="Commentaires"
                    hint="Les commentaires sont visibles en interne uniquement"
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>
            </v-container>
          </base-material-card>
          <base-material-card
            color="primary"
          >
            <template v-slot:heading>
              <div
                class="display-2 font-weight-light"
              >
                Créneaux de formation
              </div>
            </template>
            <v-container class="py-0">
              <v-row
                class="ligne-creneau py-2"
                v-for="(creneau, index) in formation.creneaux"
                :key="creneau.id"
              >
                <v-checkbox
                  class="mx-1 mt-1 check-select-creneau"
                  dense
                  hide-details="true"
                  v-model="selectedCreneaux"
                  :value="creneau.id"
                />
                <v-menu
                  class="mx-1"
                  ref="menusDateCreneaux"
                  v-model="menusDateCreneaux[index]"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="creneau.dateFormatted"
                      label="Date"
                      hide-details="true"
                      dense
                      v-bind="attrs"
                      v-on="on"
                      :rules="[v => !!v || 'Indiquez la date du créneau']"
                      required
                      @click="setColorJoursFeries()"
                      @change="formEdited = true"
                    />
                  </template>
                  <v-date-picker
                    class="mt-0 create-formation-date-picker"
                    v-model="creneau.date"
                    :first-day-of-week="1"
                    locale="fr"
                    @input="menusDateCreneaux[index] = false; updateCreneau(creneau);"
                    :pickerDate.sync="pickerDate"
                  />
                </v-menu>
                <v-text-field
                  class="mx-1 hide-clock"
                  outlined
                  label="Heure début"
                  hide-details="true"
                  v-model="creneau.heureDebut"
                  type="time"
                  dense
                  @input="updateCreneau(creneau);"
                  @change="formEdited = true"
                />
                <v-text-field
                  class="mx-1 hide-clock"
                  outlined
                  label="Heure fin"
                  hide-details="true"
                  v-model="creneau.heureFin"
                  type="time"
                  dense
                  @blur="updateCreneau(creneau);"
                  @change="formEdited = true"
                />
                <v-autocomplete
                  class="mx-1"
                  outlined
                  :items="speakers"
                  item-text="prenom"
                  item-value="id"
                  label="Formateur"
                  hide-details="true"
                  v-model="creneau.formateur"
                  dense
                  return-object
                  @change="formEdited = true"
                >
                  <template
                    slot="selection"
                    slot-scope="data"
                  >
                    {{ data.item.prenom }} {{ data.item.nom }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data"
                  >
                    {{ data.item.prenom }} {{ data.item.nom }}
                  </template>
                </v-autocomplete>
                <v-text-field
                  outlined
                  label="Intitulé"
                  hide-details="auto"
                  v-model="creneau.intitule"
                  dense
                  @input="creneau.intituleModifie = true"
                  @change="formEdited = true"
                />
                <v-icon
                  class="mx-1"
                  @click="formation.creneaux.splice(formation.creneaux.indexOf(creneau), 1)"
                >
                  mdi-delete
                </v-icon>
              </v-row>
              <v-row
                v-if="formation.creneaux.length > 0"
                class="ligne-creneau py-2"
              >
                <v-checkbox
                  class="mx-1 mt-1"
                  id="check-select-all-creneaux"
                  dense
                  hide-details="true"
                  :indeterminate="selectedCreneaux.length > 0 && selectedCreneaux.length < formation.creneaux.length"
                  :value="selectedCreneaux.length === formation.creneaux.length"
                  @change="selectAllCreneaux()"
                />
                <label
                  for="check-select-all-creneaux"
                  class="mt-2"
                >
                  <span v-if="selectedCreneaux.length < formation.creneaux.length">Tout sélectionner</span>
                  <span v-else>Tout désélectionner</span>
                </label>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                >
                  <v-btn
                    small
                    color="red"
                    :disabled="selectedCreneaux.length === 0"
                    @click="supprimerCreneaux()"
                  >
                    <v-icon
                      class="mr-3"
                    >
                      mdi-delete
                    </v-icon>
                    Supprimer les créneaux sélectionnés
                  </v-btn>
                  <v-btn
                    small
                    color="green"
                    @click="addCreneau()"
                  >
                    <v-icon
                      class="mr-3"
                    >
                      mdi-plus-circle
                    </v-icon>
                    Ajouter un créneau
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </base-material-card>
          <v-row>
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                color="gray"
                class="mx-1"
                @click="$router.back()"
              >
                Annuler
              </v-btn>
              <v-btn
                v-if="!$route.params.id"
                color="primary"
                class="mx-1"
                type="submit"
              >
                Créer la session de formation
              </v-btn>
              <v-btn
                v-if="$route.params.id"
                color="primary"
                class="mx-1"
                type="submit"
              >
                Mettre à jour la session de formation
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    data: vm => ({
      formEdited: false,
      formation: {
        intitule: 'Formation ',
        type: null,
        dateDebut: null,
        dateFin: null,
        creneaux: [],
        etat: 'OUVERT',
        reference: '',
        cours: {
          id: null,
          name: '',
        },
        salle: null,
        stagiaires: [],
        nbStagiaires: 0,
        convocationsEnvoyees: false,
        adresse: {
          adressePostale: '',
          complement: null,
          batiment: null,
          etage: null,
          codePostal: '',
          ville: '',
          pays: null,
        },
        client: null,
        nomClientFinal: null,
        factures: [],
        budgets: [],
        recettes: [],
        depenses: [],
        commentaires: '',
        couleur: `hsl(${Math.trunc(Math.floor(Math.random() * 999)) * 137.508},80%,50%)`, // couleur random pour l'agenda
      },
      types: [
        { type: 'INTER', intitule: 'Inter-entreprises' },
        { type: 'INTRA', intitule: 'Intra-entreprise' },
        { type: 'SSTRT', intitule: 'Sous-traitée' },
      ],
      formateur: null,
      clientsDirect: [],
      clientsOF: [],
      courses: [],
      speakers: [],
      isChezClient: false,
      menuDateDebut: false,
      dateDebutFormatted: '',
      menuDateFin: false,
      dateFinFormatted: '',
      menusDateCreneaux: [],
      selectedCreneaux: [],
      pickerDate: null,
      weekday: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      joursFeries: [],
      numberRule: v => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true;
        return 'La durée ne peut pas être négative';
      },
    }),

    beforeMount() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeDestroy() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeRouteLeave(to, from, next) {
      if (this.formEdited) {
        if (!window.confirm('Les modifications que vous avez apportées seront perdues, souhaitez-vous quitter la page ?')) {
          return;
        }
      }
      next();
    },

    created() {
      if (this.$route.params.id) {
        // edit mode
        this.axios.get('/formations/' + this.$route.params.id)
          .then((res) => {
            this.formation = res.data;

            // Suppression des factures dans la formation récupérée car inutiles et provoquent un bug lors de la modification
            delete this.formation.factures;

            for (const creneau of this.formation.creneaux) {
              creneau.dateFormatted = this.formatDate(creneau.date);
            }

            this.formation.creneaux.sort(function(a, b) {
              if (new Date(a.date + ' ' + a.heureDebut) > new Date(b.date + ' ' + b.heureDebut)) return 1;
              if (new Date(a.date + ' ' + a.heureDebut) < new Date(b.date + ' ' + b.heureDebut)) return -1;
            });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la récupération de la formation', type: 'warn' });
          });
      }

      if (this.$route.params.formation) {
        // copy mode
        const formation = this.$route.params.formation;

        this.formation = {
          intitule: formation.intitule,
          type: formation.type,
          dateDebut: formation.dateDebut,
          dateFin: formation.dateFin,
          creneaux: [],
          etat: 'OUVERT',
          reference: '',
          cours: formation.cours,
          salle: formation.salle,
          stagiaires: null,
          nbStagiaires: 0,
          adresse: formation.adresse ? formation.adresse : {},
          distanciel: formation.distanciel,
          client: ['INTRA', 'SSTRT'].includes(formation.type) ? formation.client : {},
          nomClientFinal: formation.nomClientFinal,
          budgets: formation.budgets,
          commentaires: formation.commentaires,
        };
        this.createCreneaux();
      }

      // Formation créée à partir d'un cours
      if (this.$route.params.course) {
        this.formation.cours = this.$route.params.course;
        this.updateIntituleRef();
      }

      // Formation créée à partir d'un devis
      if (this.$route.params.devis) {
        const devis = this.$route.params.devis;

        this.formation = {
          intitule: '',
          type: devis.typeFormation,
          dateDebut: devis.dateDebut,
          dateFin: devis.dateFin,
          creneaux: [],
          etat: 'OUVERT',
          reference: '',
          cours: devis.cours,
          salle: null,
          stagiaires: null,
          nbStagiaires: 0,
          convocationsEnvoyees: false,
          adresse: devis.adresse ? devis.adresse : {},
          distanciel: devis.distanciel,
          client: devis.typeFormation === 'INTRA' ? devis.clientDestinataire : {},
          nomClientFinal: null,
          budgets: [{ client: devis.budget.client, cout: devis.budget.cout, modeCalcul: devis.budget.modeCalcul }],
          commentaires: '',
        };
        this.createCreneaux();
        this.updateIntituleRef();
      }

      this.axios.get('/clients/direct')
        .then((res) => {
          this.clientsDirect = res.data;
          this.clientsDirect.sort((a, b) => a.nomCommercial.localeCompare(b.nomCommercial));
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des clients', type: 'warn' });
        });

      this.axios.get('/clients/organisme')
        .then((res) => {
          this.clientsOF = res.data;
          this.clientsOF.sort((a, b) => a.nomCommercial.localeCompare(b.nomCommercial));
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des clients', type: 'warn' });
        });

      this.axios.get('/cours')
        .then((res) => {
          this.courses = res.data;
          this.courses.sort((a, b) => a.name.localeCompare(b.name));
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des cours', type: 'warn' });
        });

      this.axios.get('/intervenants')
        .then((res) => {
          this.speakers = res.data;
          this.speakers.sort((a, b) => a.nom.localeCompare(b.nom));
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des formateurs', type: 'warn' });
        });

      this.getJoursFeries();
    },

    watch: {
      'formation.dateDebut'(val) {
        this.dateDebutFormatted = this.formatDate(this.formation.dateDebut);
        this.menuDateDebut = false;
        this.openDateFin();
      },

      'formation.dateFin'(val) {
        this.dateFinFormatted = this.formatDate(this.formation.dateFin);
        this.menuDateDebut = false;
        this.createCreneaux();
      },

      pickerDate(val) {
        this.setColorJoursFeries();
      },
    },

    methods: {
      validate() {
        this.formEdited = false;
        if (this.$refs.formation.validate()) {
          if (this.$route.params.id) {
            // edit mode
            this.updateFormation();
          } else {
            // create mode
            this.createFormation();
          }
        } else {
          this.setSnack({ text: 'Veuillez renseigner tous les champs obligatoires', type: 'warn' });
        }
      },

      createFormation() {
        this.formation.cours = { id: this.formation.cours.id };

        if (this.formation.client && Object.keys(this.formation.client).length === 0) {
          this.formation.client = null;
        }

        if (this.formation.nomClientFinal === '') {
          this.formation.nomClientFinal = null;
        }

        if (this.formation.cours.id == null) {
          this.formation.cours = null;
        }

        if (['INTRA', 'SSTRT'].includes(this.formation.type) && this.formation.client) {
          delete this.formation.client.contactsAdministratif;
          delete this.formation.client.contactsCommercial;
          delete this.formation.client.contactsFacturation;
        }

        // TODO : Si on décide de gérer les formations mixtes, changer cela :
        if (this.formation.distanciel) {
          this.formation.adresse = null;
        }

        // Si la date de fin est antérieure à la date du jour, marquer comme terminée
        if (new Date() > new Date(this.formation.dateFin)) {
          this.formation.etat = 'TERMINE';
        }

        // Dans le cas d'une formation dupliquée : dupliquer les budgets
        if (this.formation.budgets) {
          this.formation.budgets = this.formation.budgets.map(budget => {
            // Utiliser la déstructuration pour obtenir toutes les clés sauf 'id'
            const { id, ...nouveauBudget } = budget;
            return nouveauBudget;
          });
        }

        this.axios.post('/formations', this.formation)
          .then(res => {
            // Si la formation a été créée à partir d'un devis, le mettre à jour avec les infos de la formation
            if (this.$route.params.devis) {
              const devis = this.$route.params.devis;
              devis.formationAssociee = res.data;
              devis.lieAFormation = true;
              this.axios.put('/devis/' + devis.id, devis);

              // Ajouter ensuite les stagiaires
              // TODO : voir pourquoi ça ne fonctionne pas lorsqu'on ajoute directement la liste des stagiaires lors de la création de la formation (erreur "detached entity" pour la société du stagiaire)
              const stagiaire = {
                nom: 'À compléter',
                prenom: 'À compléter',
                sexe: 'm',
                dateNaissance: null,
                adresse: null,
                email: '',
                telephone: '',
                societe: devis.clientDestinataire,
                type: 'SALARIE',
                signature: null,
              };

              const stagiaires = [];
              for (let i = 0; i < devis.nbStagiaires; i++) {
                stagiaires.push(stagiaire);
              }

              this.axios.patch('/formations/' + res.data.id + '/addStagiaires', stagiaires);
            }
            return res;
          })
          .then(res => {
            this.setSnack({ text: 'La session de formation a bien été créée', type: 'success' });
            this.$router.push({ name: 'FormationDetail', params: { id: res.data.id } });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la création de la formation', type: 'warn' });
          });
      },

      updateFormation() {
        this.lightDataFormation();

        this.axios.put('/formations/' + this.formation.id, this.formation)
          .then(res => {
            this.setSnack({ text: 'La session de formation a bien été mise à jour', type: 'success' });
            this.$router.push({ name: 'FormationDetail', params: { id: res.data.id } });

            return this.success;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la mise à jour de la formation', type: 'warn' });
            this.fail = true;
          })
          .finally(() => this.loadingRequest);
      },

      updateIntituleRef() {
        this.formation.intitule = 'Formation ' + this.formation.cours.name;
        this.formation.reference = this.formation.cours.reference;
        this.updateNumberRef();
      },

      updateNumberRef() {
        this.axios.get('/formations/nextRefNumber/' + this.formation.cours.id)
          .then((res) => {
            let num = '000';
            if (res.data < 10) {
              num = '00' + res.data;
            } else if (res.data < 100) {
              num = '0' + res.data;
            } else {
              num = res.data;
            }
            this.formation.reference += num;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la récupération de la référence de la formation', type: 'warn' });
          })
      },

      selectAllCreneaux() {
        if (this.selectedCreneaux.length === this.formation.creneaux.length) {
          // Désélectionner tout
          this.selectedCreneaux = [];
        } else {
          this.selectedCreneaux = this.formation.creneaux.map(c => c.id);
        }
      },

      supprimerCreneaux() {
        this.formation.creneaux = this.formation.creneaux.filter(creneau => !this.selectedCreneaux.includes(creneau.id));
      },

      fillAdresse() {
        if (this.isChezClient) {
          this.formation.adresse = this.formation.client.adresse;
        } else {
          this.formation.adresse = {};
        }
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },

      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

      openDateFin() {
        let dateFinParsed;
        const dateToday = new Date().toISOString().substring(0, 10);
        const dateFin = new Date(this.formation.dateFin).toISOString().substring(0, 10);

        if (dateToday === dateFin || new Date(this.formation.dateFin) < new Date(this.formation.dateDebut)) {
          this.formation.dateFin = this.formation.dateDebut;

          if (this.formation.cours.id != null) {
            dateFinParsed = new Date(this.formation.dateFin);
            dateFinParsed.setDate(dateFinParsed.getDate() + this.formation.cours.nbDays - 1);
            this.formation.dateFin = dateFinParsed.toISOString().substring(0, 10);
          }

          this.menuDateFin = true;
          this.setColorJoursFeries();
        }
      },

      dateDiffInDays(a, b) {
        const utc1 = new Date(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = new Date(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / 86400000) + 1;
      },

      addDaysToDate(date, nbDays) {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + nbDays);
        return newDate;
      },

      calculateDuree() {
        // Ne pas supprimer
        return this.dateDiffInDays(new Date(this.formation.dateDebut), new Date(this.formation.dateFin));
      },

      createCreneaux() {
        if (this.formation.dateDebut && this.formation.dateFin && !this.formation.creneaux.length) {
          const duree = this.calculateDuree();
          for (let i = 0; i < duree; i++) {
            const jourCreneau = this.addDaysToDate(this.formation.dateDebut, i);
            this.formation.creneaux.push({
              intitule: this.weekday[jourCreneau.getDay()] + ' matin',
              intituleModifie: false,
              date: new Date(jourCreneau).toISOString().substring(0, 10),
              dateFormatted: this.formatDate(new Date(jourCreneau).toISOString().substring(0, 10)),
              heureDebut: '09:00',
              heureFin: '12:30',
              dureeHeures: 3.5,
              formateur: this.formateur,
            });
            this.formation.creneaux.push({
              intitule: this.weekday[jourCreneau.getDay()] + ' après-midi',
              intituleModifie: false,
              date: new Date(jourCreneau).toISOString().substring(0, 10),
              dateFormatted: this.formatDate(new Date(jourCreneau).toISOString().substring(0, 10)),
              heureDebut: '13:30',
              heureFin: '17:00',
              dureeHeures: 3.5,
              formateur: this.formateur,
            });
          }
        }
      },

      updateFormateurCreneaux() {
        for (const creneau of this.formation.creneaux) {
          if (!creneau.formateur) {
            creneau.formateur = this.formateur;
          }
        };
      },

      updateCreneau(creneau) {
        creneau.dateFormatted = this.formatDate(creneau.date);

        // S'assurer que heureFin >= heureDebut
        if (creneau.heureFin && creneau.heureFin < creneau.heureDebut) {
          creneau.heureFin = creneau.heureDebut;
        }

        // Nommer le créneau
        if (!creneau.intituleModifie) {
          creneau.intitule = this.weekday[new Date(creneau.date).getDay()];
          if (creneau.heureDebut > '00:00' && creneau.heureDebut < '12:00') {
            creneau.intitule += ' matin';
          } else if (creneau.heureDebut >= '12:00' && creneau.heureDebut < '17:30') {
            creneau.intitule += ' après-midi';
          } else if (creneau.heureDebut >= '17:30' && creneau.heureDebut < '23:59') {
            creneau.intitule += ' soir';
          }
          creneau.intituleModifie = false;
        }
        // Mettre à jour éventuellement la date de début ou fin
        if (new Date(creneau.date) < new Date(this.formation.dateDebut)) {
          this.formation.dateDebut = creneau.date;
        } else if (new Date(creneau.date) > new Date(this.formation.dateFin)) {
          this.formation.dateFin = creneau.date;
        }
      },

      addCreneau() {
        let dateNewCreneau = '';
        if (this.formation.dateFin) {
          dateNewCreneau = new Date(this.formation.dateFin);
          dateNewCreneau.setDate(dateNewCreneau.getDate() + 1);
        } else {
          dateNewCreneau = new Date();
        }

        this.formation.creneaux.push({
          intitule: '',
          intituleModifie: false,
          date: dateNewCreneau.toISOString().substring(0, 10),
          dateFormatted: '',
          heureDebut: '',
          heureFin: '',
          // TODO : Calculer la durée en fonction des heures
          dureeHeures: 0,
          formateur: this.formateur,
        });
      },

      getJoursFeries() {
        this.axios('/planning/joursFeries/' + new Date().getFullYear())
          .then((res) => {
            for (const date of Object.entries(res.data)) {
              this.joursFeries.push(date[0]);
            }
            return this.axios('/planning/joursFeries/' + (new Date().getFullYear() + 1))
          })
          .then((res) => {
            for (const date of Object.entries(res.data)) {
              this.joursFeries.push(date[0]);
            }
            return res;
          })
      },

      setColorJoursFeries() {
        // setTimeout de 10ms pour laisser le temps de s'afficher
        setTimeout(() => {
          var allDates = document.querySelectorAll('.v-date-picker-table .v-btn .v-btn__content');
          // filtrer uniquement les jours fériés sur le mois courant
          var dates = this.joursFeries.filter(jour => this.pickerDate === jour.substring(0, 7)).map(jour => parseInt(jour.split('-')[2]));

          allDates.forEach((x, i) => {
            if (dates.includes(parseInt(x.innerHTML))) {
              allDates[i].classList.add('date-gris');
            }
          })
        }, 10)
      },

      saveDateDebut(date) {
        this.$refs.menuDateDebut.save(date)
      },

      saveDateFin(date) {
        this.$refs.menuDateFin.save(date)
      },

      lightDataFormation() {
        if (['INTRA', 'SSTRT'].includes(this.formation.type) && this.formation.client) {
          delete this.formation.client.contactsAdministratif;
          delete this.formation.client.contactsCommercial;
          delete this.formation.client.contactsFacturation;
        }

        this.formation.recettes.forEach(recette => {
          delete recette.client.contacts;
          delete recette.client.contactsAdministratif;
          delete recette.client.contactsCommercial;
          delete recette.client.contactsFacturation;
        });

        this.formation.depenses.forEach(depense => {
          delete depense.client.contacts;
          delete depense.client.contactsAdministratif;
          delete depense.client.contactsCommercial;
          delete depense.client.contactsFacturation;
        });

        this.formation.devis.forEach(devis => {
          delete devis.clientDestinataire.contacts;
          delete devis.clientDestinataire.contactsAdministratif;
          delete devis.clientDestinataire.contactsCommercial;
          delete devis.clientDestinataire.contactsFacturation;
        });
      },

      preventNav(event) {
        if (this.formEdited) {
          event.preventDefault();
          event.returnValue = '';
          this.formEdited = false;
        }
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>

<style scoped>
  /* Griser les weekends et jours fériés */
  .create-formation-date-picker >>> tr td:nth-child(6) .v-btn__content,
  .create-formation-date-picker >>> tr td:nth-child(7) .v-btn__content,
  .create-formation-date-picker >>> .date-gris
  {
    color: #b2b2b2;
  }

  #create-formation >>> .ligne-creneau input,
  #create-formation >>> .ligne-creneau .v-select__selections {
    font-size: 0.9em;
  }

  #create-formation >>> .ligne-creneau:nth-child(2n) {
    background-color: #f4f4f4a6;
  }

  #create-formation >>> .hide-clock input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }

</style>
